import {OrderTimePlaceSelector} from "../order-time-place-selector/order-time-place-selector";
import {Input} from "../input/input";
import {carInfo} from "../car-info/car-info";
import {orderSummary as OrderSummaryFunction} from "../order-summary/order-summary";
import {axios} from "../index";

let $order = document.querySelector('.order');

if ($order) {
    order({$wrapper: $order})
}

export function order({
                          $wrapper,
                          car = window.orderCar,
                          rate = window.orderRate,
                          startDate = window.orderDateStart,
                          endDate = window.orderDateEnd,
                          deliveries = window.orderDeliveries,
                          orderOptions = window.orderOptions,
                      }) {

    const classPrefix = 'order';
    const dataPrefix = 'data-order';
    const buttonLoadingClass = 'button--load'

    const $carInfo = $wrapper.querySelector(`[${dataPrefix}-car-info]`)
    const $orderSummary = $wrapper.querySelector(`[${dataPrefix}-order-summury]`)
    const $startTimePlaceWrapper = $wrapper.querySelector(`[${dataPrefix}-start-time-place]`)
    const $endTimePlaceWrapper = $wrapper.querySelector(`[${dataPrefix}-end-time-place]`)
    const $error = $wrapper.querySelector(`[${dataPrefix}-error]`)

    const sendButtons = $wrapper.querySelectorAll(`[${dataPrefix}-send]`)
    const paymentMethods = $wrapper.querySelectorAll(`[${dataPrefix}-payment]`)

    const $lnameWrapper = $wrapper.querySelector(`[${dataPrefix}-input-lname]`)
    const $fnameWrapper = $wrapper.querySelector(`[${dataPrefix}-input-fname]`)
    const $mnameWrapper = $wrapper.querySelector(`[${dataPrefix}-input-mname]`)
    const $phoneWrapper = $wrapper.querySelector(`[${dataPrefix}-input-phone]`)
    const $emailWrapper = $wrapper.querySelector(`[${dataPrefix}-input-email]`)

    const $paymentInput = $wrapper.querySelector(`[name="pay-type"]`)

    const $vuSeriesWrapper = $wrapper.querySelector(`[${dataPrefix}-input-vu-series]`)
    const $vuDateWrapper = $wrapper.querySelector(`[${dataPrefix}-input-vu-date]`)
    const $birthdayWrapper = $wrapper.querySelector(`[${dataPrefix}-input-birthday]`)
    const $experienceWrapper = $wrapper.querySelector(`[${dataPrefix}-input-experience]`)
    const $passportWrapper = $wrapper.querySelector(`[${dataPrefix}-input-passport]`)
    const $passportFromWrapper = $wrapper.querySelector(`[${dataPrefix}-input-passport-from]`)
    const $passportTakeWrapper = $wrapper.querySelector(`[${dataPrefix}-input-passport-take]`)
    const $passportLocationWrapper = $wrapper.querySelector(`[${dataPrefix}-input-passport-location]`)
    const $commentWrapper = $wrapper.querySelector(`[${dataPrefix}-input-comment]`)

    let startTimePlaceSelector = null
    let endTimePlaceSelector = null
    let orderSummary = null

    let orderData = {
        car: car.id,
        startDate: startDate,
        endDate: endDate,
        fullPrice: 0,
        rate,
        formValues: {},
        orderOptions
    }

    init()

    function init() {
        addEventListeners()

        carInfo({
            $wrapper: $carInfo,
            car
        })

        orderSummary = new OrderSummaryFunction({$wrapper: $orderSummary})

        startTimePlaceSelector = new OrderTimePlaceSelector({
            $wrapper: $startTimePlaceWrapper,
            isAllowSelectTime: +rate.choose_pickup_time === 1,
            defaultTime: rate.pickup_time_default,
            isAllowSelectPlace: +rate.choose_pickup === 1,
            defaultPlace: +rate.pickup_default,
            minTime: null,
            maxTime: startDate === endDate ? rate.dropoff_time_default : null,
            deliveries,
            callback: ({time, delivery, otherDelivery}) => {
                orderData.startTime = time
                orderData.startDelivery = delivery
                orderData.startOtherDelivery = otherDelivery

                if (startDate === endDate) {
                    let intervalId = setInterval(() => {
                        if (endTimePlaceSelector) {
                            endTimePlaceSelector.updateTimeLimits({
                                minTime: time
                            });
                            clearInterval(intervalId);  // Останавливаем интервал после успешного выполнения
                        }
                    }, 50);
                }

                orderSummary.setData(orderData)
            }
        })

        endTimePlaceSelector = new OrderTimePlaceSelector({
            $wrapper: $endTimePlaceWrapper,
            isAllowSelectTime: +rate.choose_dropoff_time === 1,
            defaultTime: startDate === endDate && +rate.max_days > 0 ? '18:00' : rate.dropoff_time_default,
            isAllowSelectPlace: +rate.choose_dropoff === 1,
            defaultPlace: +rate.dropoff_default,
            minTime: startDate === endDate ? rate.pickup_time_default : null,
            maxTime: null,
            deliveries,
            callback: ({time, delivery, otherDelivery}) => {
                orderData.endTime = time
                orderData.endDelivery = delivery
                orderData.endOtherDelivery = otherDelivery

                if (startDate === endDate) {
                    let intervalId = setInterval(() => {
                        if (startTimePlaceSelector) {
                            startTimePlaceSelector.updateTimeLimits({
                                maxTime: time
                            })
                            clearInterval(intervalId);  // Останавливаем интервал после успешного выполнения
                        }
                    }, 50);
                }

                orderSummary.setData(orderData)
            }
        })

        orderData.formValues = {
            lname: {value: null, isValid: false},
            fname: {value: null, isValid: false},
            phone: {value: null, isValid: false},
        };

        orderData.inputs = []

        orderData.inputs.push(new Input({
            $wrapper: $lnameWrapper,
            type: 'text',
            label: 'Фамилия',
            name: 'lname',
            isRequired: true,
            validationType: null,
            callback: ({value, isValid}) => {
                orderData.formValues['lname'] = {value: value, isValid: isValid}
            }
        }))
        orderData.inputs.push(new Input({
            $wrapper: $mnameWrapper,
            type: 'text',
            label: 'Отчество',
            name: 'mname',
            isRequired: true,
            validationType: null,
            callback: ({value, isValid}) => {
                orderData.formValues['mname'] = {value: value, isValid: isValid}
            }
        }))
        orderData.inputs.push(new Input({
            $wrapper: $fnameWrapper,
            type: 'text',
            label: 'Имя',
            name: 'fname',
            isRequired: true,
            validationType: null,
            callback: ({value, isValid}) => {
                orderData.formValues['fname'] = {value: value, isValid: isValid}
            }
        }))
        orderData.inputs.push(new Input({
            $wrapper: $phoneWrapper,
            type: 'tel',
            label: 'Телефон',
            name: 'phone',
            isRequired: true,
            validationType: 'phone',
            callback: ({value, isValid}) => {
                orderData.formValues['phone'] = {value: value, isValid: isValid}
            }
        }))
        orderData.inputs.push(new Input({
            $wrapper: $emailWrapper,
            type: 'text',
            label: 'E-mail',
            name: 'email',
            isRequired: false,
            validationType: 'email',
            callback: ({value, isValid}) => {
                orderData.formValues['email'] = {value: value, isValid: isValid}
            }
        }))
        orderData.inputs.push(new Input({
            $wrapper: $vuSeriesWrapper,
            type: 'text',
            label: 'Номер и серия ВУ',
            name: 'vu_series',
            isRequired: false,
            validationType: null,
            callback: ({value, isValid}) => {
                orderData.formValues['vu_series'] = {value: value, isValid: isValid}
            }
        }))
        orderData.inputs.push(new Input({
            $wrapper: $vuDateWrapper,
            type: 'text',
            label: 'Дата выдачи ВУ',
            name: 'vu_date',
            isRequired: false,
            validationType: null,
            callback: ({value, isValid}) => {
                orderData.formValues['vu_date'] = {value: value, isValid: isValid}
            }
        }))
        orderData.inputs.push(new Input({
            $wrapper: $birthdayWrapper,
            type: 'text',
            label: 'Дата рождения',
            name: 'birthday',
            isRequired: false,
            validationType: null,
            callback: ({value, isValid}) => {
                orderData.formValues['birthday'] = {value: value, isValid: isValid}
            }
        }))
        orderData.inputs.push(new Input({
            $wrapper: $experienceWrapper,
            type: 'text',
            label: 'Стаж вождения',
            name: 'experience',
            isRequired: false,
            validationType: null,
            callback: ({value, isValid}) => {
                orderData.formValues['experience'] = {value: value, isValid: isValid}
            }
        }))
        orderData.inputs.push(new Input({
            $wrapper: $passportWrapper,
            type: 'text',
            label: 'Серия и номер паспорта',
            name: 'passport',
            isRequired: false,
            validationType: null,
            callback: ({value, isValid}) => {
                orderData.formValues['passport'] = {value: value, isValid: isValid}
            }
        }))
        orderData.inputs.push(new Input({
            $wrapper: $passportFromWrapper,
            type: 'text',
            label: 'Дата выдачи паспорта',
            name: 'passport_from',
            isRequired: false,
            validationType: null,
            callback: ({value, isValid}) => {
                orderData.formValues['passport_from'] = {value: value, isValid: isValid}
            }
        }))
        orderData.inputs.push(new Input({
            $wrapper: $passportTakeWrapper,
            type: 'text',
            label: 'Кем выдан',
            name: 'passport_take',
            isRequired: false,
            validationType: null,
            callback: ({value, isValid}) => {
                orderData.formValues['passport_take'] = {value: value, isValid: isValid}
            }
        }))
        orderData.inputs.push(new Input({
            $wrapper: $passportLocationWrapper,
            type: 'text',
            label: 'Место регистрации',
            name: 'passport_location',
            isRequired: false,
            validationType: null,
            callback: ({value, isValid}) => {
                orderData.formValues['passport_location'] = {value: value, isValid: isValid}
            }
        }))
        orderData.inputs.push(new Input({
            $wrapper: $commentWrapper,
            type: 'text',
            label: 'Комментарий',
            name: 'comment',
            isRequired: false,
            validationType: null,
            callback: ({value, isValid}) => {
                orderData.formValues['comment'] = {value: value, isValid: isValid}
            }
        }))
    }

    function showErrors(error) {
        $error.classList.remove(`${classPrefix}__error--hidden`)
        $error.innerText = error
    }

    function hideErrors() {
        $error.innerText = ''
        $error.classList.add(`${classPrefix}__error--hidden`)
    }

    function addEventListeners() {


        sendButtons.forEach(($sendButton) => {
            $sendButton.addEventListener('click', () => {
                orderData.inputs.forEach(input => {
                    input.validateAndSetValue();
                })

                console.log(orderData)

                if ($sendButton.classList.contains(buttonLoadingClass)) {
                    return
                }

                if (!orderData.startDelivery) {
                    showErrors('Укажите место получения')
                    return
                }

                if (!orderData.endDelivery) {
                    showErrors('Укажите место возврата')
                    return
                }

                if (orderData.startDelivery === 'other' && !orderData.startOtherDelivery) {
                    showErrors('Укажите место получения')
                    return
                }

                if (orderData.endDelivery === 'other' && !orderData.endOtherDelivery) {
                    showErrors('Укажите место возврата')
                    return
                }

                if (orderData.formValues['lname'].isValid === false) {
                    showErrors('Укажите фамилию')
                    return
                }

                if (orderData.formValues['fname'].isValid === false) {
                    showErrors('Укажите имя')
                    return
                }

                if (orderData.formValues['phone'].isValid === false) {
                    showErrors('Укажите телефон')
                    return
                }

                hideErrors()

                let isOtherValid = true;

                Object.keys(orderData.formValues).forEach(key => {
                    if (orderData.formValues[key].value==='' && key !== 'comment') {
                        isOtherValid = false;
                    }
                })

                if (!isOtherValid) {
                    showErrors('Заполните все поля в форме')
                    return
                }

                const inputsData = Object.keys(orderData.formValues).reduce((acc, key) => {
                    console.error()
                    acc[key] = orderData.formValues[key].value;
                    return acc;
                }, {});

                const calculatedPriceData = orderSummary.getData();

                const data = {
                    ...inputsData,
                    ...orderOptions,
                    ...calculatedPriceData,
                    vehicle_id: car.id,
                    rate_id: rate.id,
                    pay_type: $wrapper.querySelector('input[name="pay-type"]:checked').value,
                    take_location_id: orderData.startDelivery !== 'other' ? orderData.startDelivery : null,
                    return_location_id: orderData.endDelivery !== 'other' ? orderData.endDelivery : null,
                    take_location: orderData.startDelivery === 'other' ? orderData.startOtherDelivery : null,
                    return_location: orderData.endDelivery === 'other' ? orderData.endOtherDelivery : null,
                }

                $sendButton.classList.add(buttonLoadingClass)

                axios.post('/order/create', data).then((response) => {
                    $sendButton.classList.remove(buttonLoadingClass)

                    function sendYandexGoalAndRedirect() {
                        return new Promise((resolve, reject) => {
                            let goalSent = false;

                            ym(ymCounterId, 'reachGoal', 'order_complete', null, function () {
                                goalSent = true;
                                resolve();
                            });

                            setTimeout(() => {
                                if (!goalSent) {
                                    reject();
                                }
                            }, 1000);
                        });
                    }

                    async function handleGoalAndRedirect() {
                        try {
                            await sendYandexGoalAndRedirect();
                        } catch (error) {
                            console.log('Failed to send goal within the timeout period or Yandex is blocked');
                        } finally {
                            window.location.href = `/order-complete/${response.data.data}`
                        }
                    }

                    handleGoalAndRedirect();
                }).catch((error) => {
                    console.error(error)

                    $sendButton.classList.remove(buttonLoadingClass)
                })

            })
        })

        paymentMethods.forEach(($paymentMethod) => {
            $paymentMethod.addEventListener('click', () => {
                $paymentMethod.querySelector('input').checked = true
            })
        })

    }

}
