let $wrappers = document.querySelectorAll('.wrapper')


$wrappers.forEach($wrapper => {

})

export function openContainer($container, display = 'flex') {
    if ($container.style.display === 'none') {
        $container.style.display = display;
    } else {
        $container.style.display = 'none';
    }
}


export function scrollToContainer($container) {
    if (!$container) return;

    const offsetTop = $container.getBoundingClientRect().top;
    const offsetLeft = $container.getBoundingClientRect().left;

    // Можно установить желаемое поведение прокрутки (smooth - плавная, auto - мгновенная)
    const options = {
        behavior: 'smooth',
        block: 'start',
        inline: 'start'
    };

    window.scrollTo({
        top: (offsetTop + window.scrollY) - 80,
        left: offsetLeft + window.scrollX,
        ...options
    });


}
