import GLightbox from 'glightbox';

const $photos = document.querySelectorAll('.about-company');
if ($photos) {
    photos()
}

function photos() {
    GLightbox({
        selector: '.about-company__item',
        loop: true
    });
}