let $containers = document.querySelectorAll('.prices__container')

let wrapActiveClass = 'prices__container-head--active'

$containers.forEach($container => {
    let $containerHead = $container.querySelector('.prices__container-head')
    let $containerBtn = $container.querySelector('.prices__container-head-wrap')

    $containerHead.addEventListener('click', (event) => {
        if ($containerHead.classList.contains(wrapActiveClass)) {
            $containerHead.classList.remove(wrapActiveClass);
        } else {
            $containerHead.classList.add(wrapActiveClass);
        }
    })
})
