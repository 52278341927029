export function carInfo({
                            $wrapper,
                            car
                        }) {
    const classPrefix = 'car-info'
    const dataPrefix = 'data-car-info'

    const $template = document.querySelector(`[${dataPrefix}-template]`)

    init()

    function init() {
        const $clone = document.importNode($template.content, true)
        const $img = $clone.querySelector(`[${dataPrefix}-img]`)
        const $title = $clone.querySelector(`[${dataPrefix}-title]`)
        const $year = $clone.querySelector(`[${dataPrefix}-year]`)
        const $transmission = $clone.querySelector(`[${dataPrefix}-transmission]`)

        let src = ''
        const images = car.media.filter(media => media.collection_name === 'main')

        if (images.length > 0) {
            src = `/storage/vehicles/${images[0].id}/${images[0].file_name}`
        }
        $img.src = src
        $title.innerHTML = car.name
        $year.innerHTML = car.year
        $transmission.innerHTML = car.kpp_type
        $wrapper.appendChild($clone)
    }


}
