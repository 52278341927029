export default function calcOptionsPrice({
                                             busterCount,
                                             phoneholderCount = 0,
                                             childseatCount,
                                             durationInDays,
                                         }) {

    const busterCountToCalc = busterCount >= 1 && durationInDays >= 3 ? busterCount - 1 : busterCount;
    const childseatCountToCalc = childseatCount >= 1 && durationInDays >= 3 ? childseatCount - 1 : childseatCount;

    const busterPricePerOne = 150;
    const childseatPricePerOne = 250;

    const busterPrice = busterCountToCalc * busterPricePerOne * durationInDays
    const childseatPrice = childseatCountToCalc * childseatPricePerOne * durationInDays

    const option_buster = busterCount
    const option_buster_price = busterPrice
    const option_childseat = childseatCount
    const option_childseat_price = childseatPrice
    const option_phoneholder = phoneholderCount
    const option_phoneholder_price = 0
    const options_price = busterPrice + childseatPrice

    return {
        option_buster,
        option_buster_price,
        option_childseat,
        option_childseat_price,
        option_phoneholder,
        option_phoneholder_price,
        options_price,
    }
}