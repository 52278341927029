import {replacePhoneSymbols} from "./replace-phone-symbols";

export function Input({
                          $wrapper,
                          type = 'text',
                          label,
                          name,
                          isRequired = false,
                          validationType = null,
                          callback = () => {
                          }
                      }) {

    const classPrefix = 'input';
    const dataPrefix = 'data-input';
    const classRequired = 'input__form--require'
    const classError = 'input__form--error'

    const $template = document.querySelector(`[${dataPrefix}-template]`);
    let $form = null;
    let $input = null;
    let $label = null;
    let isValid = null;

    init()

    function init() {
        const $clone = document.importNode($template.content, true);

        $form = $clone.querySelector(`[${dataPrefix}-form]`);
        $input = $form.querySelector(`[${dataPrefix}-input]`);
        $label = $form.querySelector(`[${dataPrefix}-label]`);

        $label.innerText = label;
        $input.setAttribute('name', name)

        setInputType();

        if (isRequired) {
            $input.classList.add(classRequired);
        }

        $input.addEventListener("blur", validateAndSetValue);

        $wrapper.appendChild($clone);
    }

    function setInputType() {
        $input.setAttribute('type', type)

        if (type === 'tel') {
            $input.setAttribute('pattern', '[0-9]{3}-[0-9]{3}-[0-9]{4}')
            $input.setAttribute('placeholder', '+7')
            $input.dataset.mask = '+{7}(000)000-00-00';
            $input.classList.add('replace-phone-symbols-face')

            let $phoneBackInput = document.createElement("input");
            $phoneBackInput.classList.add('replace-phone-symbols-back')
            $phoneBackInput.type = "hidden";
            $phoneBackInput.name = "phone";
            $form.appendChild($phoneBackInput);
            replacePhoneSymbols($form);
        }
    }

    function validationCheck(value) {
        isValid = true;

        if (!isRequired) {
            return
        }

        if (value.length <= 0) {
            isValid = false;
        }
        if (validationType === 'email') {
            if (!value.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
                isValid = false;
            }
        }
        if (validationType === 'phone') {
            if (value.length !== 16) {
                isValid = false;
            }
        }

        if (!isValid) {
            $input.classList.add(classError);
        } else {
            $input.classList.remove(classError);
        }
    }

    function validateAndSetValue() {
        validationCheck($input.value)
        callback({value: $input.value, isValid: isValid})
    }

    this.validateAndSetValue = validateAndSetValue;
}
