import {Calendar as CalendarFunction} from "../../calendar/calendar";
import {cookie} from "../../../../utilities/cookie/cookie";
import formatDate from "../../../../utilities/formatDate/formatDate";
import calcOptionsPrice from "../../../../utilities/calcOptionsPrice/calcOptionsPrice";
import calcRentalDuration from "../../../../utilities/calcRentalDuration/calcRentalDuration";

export function rentalCarBooking($wrapper) {
    const classPrefix = 'rental-car-booking';
    const dataPrefix = 'data-rental-car-booking';
    const hiddenClass = `${classPrefix}__hidden`;
    const showErrorClass = `${classPrefix}__form-error--show`;
    const selectsRate = Array.from($wrapper.querySelectorAll(`[${dataPrefix}-select-rate]`));
    const headersRate = Array.from($wrapper.querySelectorAll(`[${dataPrefix}-header-rate]`));
    const vehicleId = $wrapper.querySelector(`.${classPrefix}`).dataset.vehicleId;

    const selectsRateActiveClass = `${classPrefix}__select-rate--active`

    const COOKIE_DATES_NAME = 'rental_car_booking_dates';
    const COOKIE_YEAR_MONTH_NAME = 'rental_car_booking_year_month';
    const COOKIE_BUSTER_COUNT_NAME = 'rental_car_buster_count';
    const COOKIE_PHONEHOLDER_COUNT_NAME = 'rental_car_phoneholder_count';
    const COOKIE_CHILDSEAT_COUNT_NAME = 'rental_car_childseat_count';
    const COOKIE_RATE_TYPE_NAME = 'rental_car_rate_type';
    const COOKIE_RATE_ID_NAME = 'rental_car_rate_id';

    const $calendar = $wrapper.querySelector(`[${dataPrefix}-calendar]`);
    const $startDate = $wrapper.querySelector(`[${dataPrefix}-date-start]`);
    const $endDate = $wrapper.querySelector(`[${dataPrefix}-date-end]`);
    const $datesSeparator = $wrapper.querySelector(`[${dataPrefix}-dates-separator]`);
    const $toOrder = $wrapper.querySelector(`[${dataPrefix}-to-order]`);
    const $error = $wrapper.querySelector(`[${dataPrefix}-error]`);

    const $busterCounter = $wrapper.querySelector(`[${dataPrefix}-option-buster]`);
    const $phoneholderCounter = $wrapper.querySelector(`[${dataPrefix}-option-phoneholder]`);
    const $childseatCounter = $wrapper.querySelector(`[${dataPrefix}-option-childseat]`);


    const $rentPriceValue = $wrapper.querySelector(`[${dataPrefix}-rent-price-value]`);
    const $rentPriceDesc = $wrapper.querySelector(`[${dataPrefix}-rent-price-desc]`);
    const $optionsPriceValue = $wrapper.querySelector(`[${dataPrefix}-options-price-value]`);
    const $fullPriceValue = $wrapper.querySelector(`[${dataPrefix}-full-price-value]`);

    let busterCount = 0;
    let phoneholderCount = 0;
    let childseatCount = 0;

    let Calendar

    const rates = selectsRate.reduce((acc, current) => {
        const info = JSON.parse(current.dataset.rateInfo);

        acc[info.id] = info;
        return acc;
    }, {});

    let activeRate = null;
    let dates = null;
    let yearMonth = null;

    init()

    function init() {
        busterCount = +cookie.getObject(COOKIE_BUSTER_COUNT_NAME);
        phoneholderCount = +cookie.getObject(COOKIE_PHONEHOLDER_COUNT_NAME);
        childseatCount = +cookie.getObject(COOKIE_CHILDSEAT_COUNT_NAME);

        $busterCounter.value = busterCount;
        $busterCounter.dispatchEvent(new Event('change'));
        $phoneholderCounter.value = phoneholderCount;
        $phoneholderCounter.dispatchEvent(new Event('change'));
        $childseatCounter.value = childseatCount;
        $childseatCounter.dispatchEvent(new Event('change'));


        let initialDates = cookie.getObject(COOKIE_DATES_NAME) || null;
        yearMonth = cookie.getObject(COOKIE_YEAR_MONTH_NAME) || null;

        dates = initialDates && initialDates.length > 0 ? initialDates.map(date => new Date(date)) : null;

        if (initialDates) {
            if (new Date(initialDates[0]) < new Date().setHours(0, 0, 0, 0)) {
                initialDates = null;
                yearMonth = null;
                cookie.delete(COOKIE_DATES_NAME);
                cookie.delete(COOKIE_YEAR_MONTH_NAME);
            }
        }

        Calendar = new CalendarFunction({
            $wrapper: $calendar,
            onMonthChangeCallback,
            onChangeCallback,
            initialDates,
            initialYearMonth: yearMonth
        });

        const rateTypeFromCookie = cookie.getObject(COOKIE_RATE_TYPE_NAME) || 'range';
        const rateIdFromCookie = cookie.getObject(COOKIE_RATE_ID_NAME);

        if (rateIdFromCookie && selectsRate.find($selectRate => $selectRate.dataset.rateId === rateIdFromCookie)) {
            activeRate = rateIdFromCookie;
        } else if (rateTypeFromCookie === 'single') {
            const singleRateId = Object.keys(rates).find(rateId => !rates[rateId].isRange);
            activeRate = singleRateId || selectsRate[0].dataset.rateId;
        } else {
            activeRate = selectsRate[0].dataset.rateId;
        }

        selectRate(activeRate)

        update()

        addListeners()
    }

    function addListeners() {
        selectsRate.forEach($selectRate => {
            $selectRate.addEventListener('click', () => {
                selectRate($selectRate.dataset.rateId);
            });
        });

        $busterCounter.addEventListener('change', () => {
            busterCount = +$busterCounter.value;
            cookie.setObject(COOKIE_BUSTER_COUNT_NAME, busterCount);
            update()
        })

        $phoneholderCounter.addEventListener('change', () => {
            phoneholderCount = +$phoneholderCounter.value;
            cookie.setObject(COOKIE_PHONEHOLDER_COUNT_NAME, phoneholderCount);
            update()
        })

        $childseatCounter.addEventListener('change', () => {
            childseatCount = +$childseatCounter.value;
            cookie.setObject(COOKIE_CHILDSEAT_COUNT_NAME, childseatCount);
            update()
        })

        $toOrder.addEventListener('click', () => {
            $error.classList.remove(showErrorClass);
            $error.textContent = '';

            if (rates[activeRate].isRange && (!dates || dates.length !== 2)) {

                if (!dates || dates.length !== 0) {
                    $error.textContent = 'Выберите даты проката';
                } else {
                    $error.textContent = 'Выберите дату сдачи';
                }

                void $error.offsetWidth;
                $error.classList.add(showErrorClass);

                return;
            }

            if (!rates[activeRate].isRange && (!dates || dates.length !== 1)) {
                $error.textContent = 'Выберите дату проката';
                void $error.offsetWidth;
                $error.classList.add(showErrorClass);
                return;
            }

            let params = {
                start_date: formatDate(dates[0]),
                end_date: dates.length === 2 ? formatDate(dates[1]) : formatDate(dates[0]),
            };

            if (busterCount > 0) {
                params.option_buster = busterCount;
            }
            if (phoneholderCount > 0) {
                params.option_phoneholder = phoneholderCount;
            }
            if (childseatCount > 0) {
                params.option_childseat = childseatCount;
            }

            cookie.setObject(COOKIE_DATES_NAME, dates.map(date => new Date(date).toISOString()));
            cookie.setObject(COOKIE_YEAR_MONTH_NAME, yearMonth);
            cookie.setObject(COOKIE_BUSTER_COUNT_NAME, busterCount);
            cookie.setObject(COOKIE_PHONEHOLDER_COUNT_NAME, phoneholderCount);
            cookie.setObject(COOKIE_CHILDSEAT_COUNT_NAME, childseatCount);
            cookie.setObject(COOKIE_RATE_TYPE_NAME, rates[activeRate].isRange ? 'range' : 'single');
            cookie.setObject(COOKIE_RATE_ID_NAME, activeRate);

            function sendYandexGoalAndRedirect() {
                return new Promise((resolve, reject) => {
                    let goalSent = false;

                    ym(ymCounterId, 'reachGoal', 'to_order', null, function () {
                        goalSent = true;
                        resolve();
                    });

                    setTimeout(() => {
                        if (!goalSent) {
                            reject();
                        }
                    }, 1000);
                });
            }

            async function handleGoalAndRedirect() {
                try {
                    await sendYandexGoalAndRedirect();
                } catch (error) {
                    console.log('Failed to send goal within the timeout period or Yandex is blocked');
                } finally {
                    window.location.href = `/order/${vehicleId}/${rates[activeRate].id}?${new URLSearchParams(params).toString()}`;
                }
            }

            handleGoalAndRedirect();
        })
    }

    function selectRate(rateId) {

        const $selector = selectsRate.find($selectRate => $selectRate.dataset.rateId === rateId);


        if ($selector.classList.contains(selectsRateActiveClass)) {
            return;
        }

        activeRate = $selector.dataset.rateId;

        selectsRate.forEach($selectRate => {
            $selectRate.classList.remove(selectsRateActiveClass)
        })

        headersRate.forEach($headerRate => {
            if ($headerRate.dataset.rateId === activeRate) {
                $headerRate.classList.remove(hiddenClass)
            } else {
                $headerRate.classList.add(hiddenClass)
            }
        })

        $selector.classList.add(selectsRateActiveClass)

        const rateType = rates[activeRate].isRange ? 'range' : 'single'

        Calendar.toggleMode(rateType);

        cookie.setObject(COOKIE_RATE_TYPE_NAME, rateType);
        cookie.setObject(COOKIE_RATE_ID_NAME, activeRate);
    }

    function onMonthChangeCallback(currentYearMonth) {
        yearMonth = currentYearMonth;
        cookie.setObject(COOKIE_YEAR_MONTH_NAME, yearMonth);
    }

    function onChangeCallback(selectedDates) {
        cookie.setObject(COOKIE_DATES_NAME, selectedDates);
        dates = selectedDates && selectedDates.length > 0 ? selectedDates.map(date => new Date(date)) : null;
        update()
    }

    function update() {
        setTextDates()
        calcPrice()
    }

    function setTextDates() {
        if (!dates) {
            $startDate.textContent = '';
            $endDate.textContent = '';
            $datesSeparator.textContent = '';
            return;
        }

        ym(ymCounterId, 'reachGoal', 'select_dates')

        $startDate.textContent = dates[0].toLocaleDateString('ru', {day: 'numeric', month: 'long'});

        if (dates.length === 1) {
            $endDate.textContent = '';
            $datesSeparator.textContent = '';
        } else {
            $endDate.textContent = dates[1].toLocaleDateString('ru', {day: 'numeric', month: 'long'});
            $datesSeparator.textContent = '—';
        }
    }

    function calcPrice() {
        if (!activeRate) {
            return
        }

        let duration = 0;

        if (dates && dates.length === 2) {
            const startDate = {date: formatDate(dates[0]), time: '00:00'};
            const endDate = {date: formatDate(dates[1]), time: '00:00'};

            duration = calcRentalDuration(startDate, endDate).days;
        }

        duration = duration > 0 ? duration : 1;

        const pricePerDay = Math.min(...rates[activeRate].prices
            .filter(priceItem => priceItem.min_days <= duration)
            .map(priceItem => priceItem.price));

        const rentPrice = pricePerDay * duration;

        const optionsPrice = calcOptionsPrice({
            busterCount,
            phoneholderCount,
            childseatCount,
            durationInDays: duration,
        }).options_price;

        const fullPrice = rentPrice + optionsPrice;

        $rentPriceValue.textContent = `${rentPrice} ₽`;
        $rentPriceDesc.textContent = `${duration} дн. х ${pricePerDay} ₽`;
        $optionsPriceValue.textContent = `${optionsPrice} ₽`;
        $fullPriceValue.textContent = `${fullPrice} ₽`;
    }

}