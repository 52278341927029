const $toTopButton = document.querySelector('[data-to-top]')

if ($toTopButton) {
    $toTopButton.addEventListener('click', () => {
        console.log(123123)

        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    })
}

