import GLightbox from 'glightbox';

const $photos = document.querySelectorAll('.photos');
if ($photos) {
    photos()
}

function photos() {
    GLightbox({
        selector: '.photos__item',
        loop: true
    });
}