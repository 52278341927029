export const mobileBreakpoint = 1280
export const axios = require('axios');

axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

const links = Array.from(document.querySelectorAll('a'))

links.forEach((link) => {
    link.addEventListener('click', (event) => {
        if (link.href.indexOf(window.location.hostname) === -1 && link.href.indexOf('http') !== -1) {
            event.preventDefault()

            window.open(link.href, '_blank');
        }
    })
})

 const loadImage = (entries, observer) => {
    entries.forEach(entry => {
        // Если элемент является видимым
        if (entry.isIntersecting) {
            const image = entry.target;
            image.src = image.dataset.src;

            // Отменить наблюдение за текущим изображением
            observer.unobserve(image);
        }
    });
};

const options = {
    root: null,           // используем viewport
    rootMargin: '0px',    // без отступов
    threshold: 0.01       // когда 1% изображения попадает в зону видимости
};

const observer = new IntersectionObserver(loadImage, options);

document.querySelectorAll('[data-loading="lazy"]').forEach(img => {
    observer.observe(img);
});


