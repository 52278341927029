import rentalCarItem from "../rental-car/rental-car-item/rental-car-item";

const $rentalCars = document.querySelector('.rental-cars');

if ($rentalCars) {
    rentalCars($rentalCars)
}

export function rentalCars($wrapper) {
    const classPrefix = 'rental-cars';
    const dataPrefix = 'data-rental-cars';

    const $sortUp = $wrapper.querySelector(`[${dataPrefix}-sort-up]`);
    const $sortDown = $wrapper.querySelector(`[${dataPrefix}-sort-down]`);
    const sortButtons = [$sortUp, $sortDown];
    const sortActiveButtonClass = `${classPrefix}__sort-button--active`;

    const activeCar = new URL(location.href).searchParams.get('vehicle')

    const $list = $wrapper.querySelector(`[${dataPrefix}-list]`);

    const items = Array
        .from($wrapper.querySelectorAll(`[${dataPrefix}-item]`))
        .map($item => {
            return {
                id: $item.dataset.vehicleId,
                func: new rentalCarItem($item)
            }
        });

    if (activeCar) {
        items.find(item => item.id === activeCar).func.toggleForm();
    }

    sortButtons.forEach($button => {
        $button.addEventListener('click', () => {
            if ($button.classList.contains(sortActiveButtonClass)) {
                return
            }

            sortButtons.forEach($button => {
                $button.classList.remove(sortActiveButtonClass)
            })

            $button.classList.add(sortActiveButtonClass)

            $list.style.flexDirection = $button === $sortUp ? 'column' : 'column-reverse';
        })
    })

    let reachGoal = false;
    const observerCallback = (entries, observer) => {
        entries.forEach(entry => {
            if (entry.isIntersecting && !reachGoal) {
                ym(ymCounterId, 'reachGoal', 'cars_select')
                reachGoal = true;
                observer.disconnect();
            }
        });
    };

    const observerOptions = {
        root: null,
        rootMargin: '0px',
        threshold: 0.1
    };

    const observer = new IntersectionObserver(observerCallback, observerOptions);
    observer.observe($wrapper);
}