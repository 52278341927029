import flatpickr from "flatpickr";
import {Russian} from "flatpickr/dist/l10n/ru.js";
import formatDate from "../../../utilities/formatDate/formatDate";

export function Calendar({
                             $wrapper,
                             onMonthChangeCallback = () => {
                             },
                             onChangeCallback = () => {
                             },
                             initialDates = null,
                             initialYearMonth = null
                         }) {

    let currentYearMonth = initialYearMonth || null;
    let currentDates = initialDates ? initialDates.map(date => new Date(date)) : null;
    let currentDays = []

    const calendarInstance = flatpickr($wrapper, {
        locale: Russian,
        inline: true,
        mode: "range",
        minDate: new Date().setHours(0, 0, 0, 0),
        showMonths: 2,
        onReady: (selectedDates, dateStr, instance) => {
            const $container = instance.calendarContainer;
            $container.classList.add('calendar');

            $container
                .querySelector('.flatpickr-prev-month')
                .innerHTML = '<svg><use xlink:href="#arrow-thin"></use></svg>';


            $container
                .querySelector('.flatpickr-next-month')
                .innerHTML = '<svg><use xlink:href="#arrow-thin"></use></svg>';

            if (initialDates) {
                instance.setDate(initialDates);
            }

            if (currentYearMonth) {
                restoreCurrentMonths(instance);
            } else {
                currentYearMonth = saveCurrentMonths(instance);
                onMonthChangeCallback(currentYearMonth);
            }
            // Создаем контейнер для кнопок
            const navContainer = document.createElement('div');
            navContainer.className = 'flatpickr-nav-container';

            // Создаем кнопку для предыдущего месяца
            const prevButton = document.createElement('button');
            prevButton.className = 'flatpickr-prev-button';
            prevButton.innerHTML = '<svg class="flatpickr-prev-button-icon"><use xlink:href="#arrow-triangle"></use></svg>';
            prevButton.addEventListener('click', () => {
                instance.changeMonth(-1);
            });

            // Создаем кнопку для следующего месяца
            const nextButton = document.createElement('button');
            nextButton.className = 'flatpickr-next-button';
            nextButton.innerHTML = '<svg class="flatpickr-next-button-icon"><use xlink:href="#arrow-triangle"></use></svg>';
            nextButton.addEventListener('click', () => {
                instance.changeMonth(1);
            });

            // Добавляем кнопки в контейнер
            navContainer.appendChild(prevButton);
            navContainer.appendChild(nextButton);

            // Вставляем контейнер перед календарем
            instance.calendarContainer.appendChild(navContainer);

            createMonthNames(instance);
        },
        onMonthChange: (selectedDates, dateStr, instance) => {
            currentDays = []
            createMonthNames(instance);

            let previousMonths = currentYearMonth;
            const intervalId = setInterval(() => {
                const currentMonthsData = saveCurrentMonths(instance);
                if (JSON.stringify(currentMonthsData) !== JSON.stringify(previousMonths)) {
                    previousMonths = currentMonthsData;
                    currentYearMonth = currentMonthsData;
                    onMonthChangeCallback(currentYearMonth);
                    clearInterval(intervalId);
                }
            }, 10);
        },
        onChange: (selectedDates, dateStr, instance) => {
            // if (currentDates && currentDates.length === 2 && selectedDates.length === 1 && instance.config.mode === "range") {
            //     const [start, end] = currentDates;
            //     const newDate = selectedDates[0];
            //
            //     if (newDate < start) {
            //         instance.setDate([newDate, end], true);
            //     } else {
            //         instance.setDate([start, newDate], true);
            //     }
            //
            //     return;
            // }

            currentDates = selectedDates;
            restoreCurrentMonths(instance);

            onChangeCallback(selectedDates.map(date => formatDate(date)));
            createMonthNames(instance);
        },
        onDayCreate: (selectedDates, dateStr, instance, $day) => {
            if ($day.classList.contains('hidden')) {
                return;
            }

            if ($day.classList.contains('startRange')) {
                $day.classList.add('startRangeCustom');
            }

            currentDays.push($day);

            $day.addEventListener('mouseover', () => {
                if (selectedDates.length !== 1 || instance.config.mode !== 'range') {
                    return
                }

                const dayIndex = currentDays.indexOf($day);
                const startRangeIndex = currentDays.findIndex($day => $day.classList.contains('startRangeCustom'));

                if (dayIndex > startRangeIndex) {
                    currentDays[startRangeIndex].classList.remove('invert');
                } else if (dayIndex < startRangeIndex) {
                    currentDays[startRangeIndex].classList.add('invert');
                }

                currentDays.forEach((day, index) => {
                    day.classList.remove('inRange');
                    day.classList.remove('endRange');
                    day.classList.remove('startRange');

                    if (dayIndex > startRangeIndex) {
                        if (index > startRangeIndex && index < dayIndex) {
                            day.classList.add('inRange');
                        }
                    } else if (dayIndex < startRangeIndex) {
                        if (index > dayIndex && index < startRangeIndex) {
                            day.classList.add('inRange');
                        }
                    }
                })
            })
        }
    });

    function saveCurrentMonths(instance) {
        let month = [];
        const monthsElements = instance.calendarContainer.querySelectorAll('.flatpickr-month');
        monthsElements.forEach(monthElement => {
            const monthSpan = monthElement.querySelector('.cur-month');
            const yearInput = monthElement.querySelector('.cur-year');
            if (monthSpan && yearInput) {
                const monthIndex = getMonthIndex(monthSpan.textContent.trim());
                month.push({
                    year: parseInt(yearInput.value),
                    month: monthIndex
                });
            }
        });

        return month;
    }

    function getMonthIndex(monthName) {
        const monthNames = [
            'Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь',
            'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'
        ];
        return monthNames.indexOf(monthName);
    }

    function createMonthNames(instance) {
        const daysContainers = Array.from(instance.daysContainer.children);

        for (let i = 0; i < daysContainers.length; i++) {
            const monthIndex = (instance.currentMonth + i) % 12;
            const year = instance.currentYear + Math.floor((instance.currentMonth + i) / 12);
            const monthName = instance.l10n.months.longhand[monthIndex];
            const monthNameDiv = document.createElement('div');
            monthNameDiv.className = 'flatpickr-month-name';
            monthNameDiv.textContent = `${monthName} ${year}`;
            instance.daysContainer.insertBefore(monthNameDiv, daysContainers[i]);
        }
    }

    function restoreCurrentMonths(instance, yearMonth = currentYearMonth) {
        currentDays = []
        if (yearMonth.length > 0) {
            const firstMonth = yearMonth[0];
            const date = new Date(firstMonth.year, firstMonth.month, 1);
            instance.jumpToDate(date);
        }
    }

    this.toggleMode = function (newMode) {
        if (newMode === "single") {
            if (calendarInstance.selectedDates.length > 0) {
                calendarInstance.setDate(calendarInstance.selectedDates[0]);
            }
            calendarInstance.set('mode', 'single');
        } else if (newMode === "range") {
            calendarInstance.clear();
            calendarInstance.set('mode', 'range');
        }

        restoreCurrentMonths(calendarInstance);
        onChangeCallback(calendarInstance.selectedDates.map(date => formatDate(date)));
    };
}