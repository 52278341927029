const counters = document.querySelectorAll('.counter');

counters.forEach($counter => {
    counter($counter)
})

function counter($wrapper) {
    const classPrefix = 'counter';
    const dataPrefix = 'data-counter';

    const $minus = $wrapper.querySelector(`[${dataPrefix}-minus]`);
    const $plus = $wrapper.querySelector(`[${dataPrefix}-plus]`);
    const $value = $wrapper.querySelector(`[${dataPrefix}-value]`);

    const min = +$wrapper.dataset.min;
    const max = +$wrapper.dataset.max;
    const key = $wrapper.dataset.key;
    let value = $value.textContent !== '' ? +$value.textContent : 0;
    $wrapper.value = value;

    $wrapper.addEventListener('change', () => {
        $value.textContent = $wrapper.value;
        value = +$wrapper.value;
    })

    $minus.addEventListener('click', () => {
        value = value > min ? value - 1 : min;
        $value.textContent = value;
        $wrapper.value = value;
        $wrapper.dispatchEvent(new Event('change'));
    })

    $plus.addEventListener('click', () => {
        value = value < max ? value + 1 : max;
        $value.textContent = value;
        $wrapper.value = value;
        $wrapper.dispatchEvent(new Event('change'));
    })
}