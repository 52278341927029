export function formatPhoneToInterface(phone) {

    let numberLength = phone.length - 10;

    if (numberLength < 0) {
        numberLength = 0;
    }

    let up = phone.substring(numberLength);

    const n0 = up[0] ?
        up[0] :
        '';
    const n1 = up[1] ?
        up[1] :
        '';
    const n2 = up[2] ?
        up[2] :
        '';
    const n3 = up[3] ?
        up[3] :
        '';
    const n4 = up[4] ?
        up[4] :
        '';
    const n5 = up[5] ?
        up[5] :
        '';
    const n6 = up[6] ?
        up[6] :
        '';
    const n7 = up[7] ?
        up[7] :
        '';
    const n8 = up[8] ?
        up[8] :
        '';
    const n9 = up[9] ?
        up[9] :
        '';

    if (n3 == '' && n4 == ''){
        let number = `+7(${n0}${n1}${n2}`;
        return number;
    }
    if (n6 == '' && n7 == ''){
        let number = `+7(${n0}${n1}${n2})${n3}${n4}${n5}`;
        return number;
    }
    if (n8 == '' && n9 == ''){
        let number = `+7(${n0}${n1}${n2})${n3}${n4}${n5}-${n6}${n7}`;
        return number;
    }


    let number = `+7(${n0}${n1}${n2})${n3}${n4}${n5}-${n6}${n7}-${n8}${n9}`;

    return number;

}

export function formatPhoneToDB(phone) {
    phone = phone.replaceAll('-', '');
    phone = phone.replaceAll(' ', '');
    phone = phone.replaceAll('+', '');
    phone = phone.replaceAll('(', '');
    phone = phone.replaceAll(')', '');
    phone = '7' + phone.slice(-10);
    return phone;
}
