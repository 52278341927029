let $orderComplete = document.querySelector('.order-complete');


if ($orderComplete) {
    orderComplete({$wrapper: $orderComplete})
}

export function orderComplete({
                                  $wrapper,
                                  order = window.orderComplete,
                              }) {

    const classPrefix = 'order-complete';
    const dataPrefix = 'data-order-complete';
    const hiddenClass = `${classPrefix}__hidden`;

    const $carName = $wrapper.querySelector(`[${dataPrefix}-car-name]`)
    const $carYear = $wrapper.querySelector(`[${dataPrefix}-car-year]`)
    const $carRpp = $wrapper.querySelector(`[${dataPrefix}-car-kpp]`)

    const $rentStart = $wrapper.querySelector(`[${dataPrefix}-rent-start]`)
    const $rentPrice = $wrapper.querySelector(`[${dataPrefix}-rent-price]`)
    const $rentPriceTotalWrapper = $wrapper.querySelector(`[${dataPrefix}-rent-total-price-wrapper]`)
    const $takePrice = $wrapper.querySelector(`[${dataPrefix}-take-price]`)
    const $returnPrice = $wrapper.querySelector(`[${dataPrefix}-return-price]`)
    const $takeTimePrice = $wrapper.querySelector(`[${dataPrefix}-take-time-price]`)
    const $returnTimePrice = $wrapper.querySelector(`[${dataPrefix}-return-time-price]`)
    const $busterPrice = $wrapper.querySelector(`[${dataPrefix}-buster-price]`)
    const $childseatPrice = $wrapper.querySelector(`[${dataPrefix}-childseat-price]`)

    const $totalPrice = $wrapper.querySelector(`[${dataPrefix}-total-price]`)

    const $firstName = $wrapper.querySelector(`[${dataPrefix}-fname]`)


    const $startLocation = $wrapper.querySelector(`[${dataPrefix}-start-location]`)
    const $startTime = $wrapper.querySelector(`[${dataPrefix}-start-time]`)

    const $endLocation = $wrapper.querySelector(`[${dataPrefix}-end-location ]`)
    const $endTime = $wrapper.querySelector(`[${dataPrefix}-end-time]`)

    init()

    function init() {
        $rentStart.innerText = `${formatDate(order['rent_start'])} - ${formatDate(order['rent_end'])}`;
        $rentPrice.innerText = 'Прокат - ' + order['rent_price'] + ' руб.';

        let isNeedFullPrice = false;

        if (order.take_price) {
            isNeedFullPrice = true;
            $takePrice.innerText = `Получение вне офиса: ${order.take_price} руб.`;
        } else {
            $takePrice.classList.add(hiddenClass);
        }

        if (order.return_price) {
            isNeedFullPrice = true;
            $returnPrice.innerText = `Возврат вне офиса: ${order.return_price} руб.`;
        } else {
            $returnPrice.classList.add(hiddenClass);
        }

        if (order.take_time_price) {
            isNeedFullPrice = true;
            $takeTimePrice.innerText = `Получение в нерабочее время: ${order.take_time_price} руб.`;
        } else {
            $takeTimePrice.classList.add(hiddenClass);
        }

        if (order.return_time_price) {
            isNeedFullPrice = true;
            $returnTimePrice.innerText = `Возврат в нерабочее время: ${order.return_time_price} руб.`;
        } else {
            $returnTimePrice.classList.add(hiddenClass);
        }

        if (order.option_buster_price) {
            isNeedFullPrice = true;
            $busterPrice.innerText = `Бустер: ${order.option_buster_price} руб.`;
        } else {
            $busterPrice.classList.add(hiddenClass);
        }

        if (order.option_childseat_price) {
            isNeedFullPrice = true;
            $childseatPrice.innerText = `Детское кресло: ${order.option_childseat_price} руб.`;
        } else {
            $childseatPrice.classList.add(hiddenClass);
        }

        if (isNeedFullPrice) {
            $totalPrice.innerText = `Общая стоимость: ${order.rent_total_price} руб.`;
            $rentPriceTotalWrapper.classList.remove(hiddenClass);
        }

        if (order['take_location']) {
            $startLocation.innerText = order['take_location']['name']
        } else {
            $startLocation.innerText = order['other_take_location']
        }

        if (order['return_location']) {
            $endLocation.innerText = order['return_location']['name']
        } else {
            $endLocation.innerText = order['other_return_location']
        }

        $carName.innerText = order['vehicle']['name']
        $carYear.innerText = order['vehicle']['year']
        $carRpp.innerText = order['vehicle']['kpp_type']


        $firstName.innerText = order['fname']

    }
}

function formatDate(date) {
    const parts = date.split(" ");
    const dateParts = parts[0].split("-");
    return `${dateParts[2]}.${dateParts[1]}.${dateParts[0]} ${parts[1].slice(0, 5)}`;
}
