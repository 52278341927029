const contactGoalHandlers = Array.from(document.querySelectorAll('.contact-goal-handler'));

contactGoalHandlers.forEach(contactGoalHandler)

function contactGoalHandler($contact) {
    const classPrefix = 'contact-goal-handler'
    const dataPrefix = 'data-contact-goal-handler'
    const activeClass = `${classPrefix}--active`
    const renderedClass = `${classPrefix}--rendered`

    const $value = $contact.querySelector(`[${dataPrefix}-value]`)

    init()

    function init() {

        const value = $value.innerHTML;

        $value.innerHTML = `
        <span class="${classPrefix}__item-value">${value}</span>
        <span class="${classPrefix}__button"></span>
        `
        $contact.classList.add(renderedClass)

        addEventListeners()
    }

    function addEventListeners() {
        $contact.addEventListener('click', sendGoal)
        $contact.addEventListener('mouseenter', sendGoal)
    }

    function sendGoal() {
        if ($contact.classList.contains(activeClass)) {
            return;
        }

        $contact.classList.add(activeClass)

        console.log('send')
        ym(ymCounterId, 'reachGoal', 'get_contact')
    }
}