import {rentalCarInfo} from "../rental-car-info/rental-car-info";
import {rentalCarBooking} from "../rental-car-booking/rental-car-booking";

export default function rentalCarItem($wrapper) {

    const classPrefix = 'rental-car-item';
    const dataPrefix = 'data-rental-car-item';
    const hiddenClass = `${classPrefix}__hidden`;

    const $toggleFormButton = $wrapper.querySelector(`[${dataPrefix}-toggle-form]`);
    const $info = $wrapper.querySelector(`[${dataPrefix}-info]`);
    const $booking = $wrapper.querySelector(`[${dataPrefix}-booking]`);

    let initialized = false;

    $toggleFormButton.addEventListener('click', toggleForm)

    function toggleForm() {
        $info.classList.toggle(hiddenClass);
        $booking.classList.toggle(hiddenClass);

        if (!initialized) {
            ym(ymCounterId, 'reachGoal', 'toggle_form_click')
            rentalCarInfo($info);
            rentalCarBooking($booking);
            initialized = true;
        }

        window.scrollTo({
            top: $wrapper.offsetTop - 80,
            behavior: 'smooth'
        })
    }

    this.toggleForm = toggleForm
}