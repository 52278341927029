import {validationCheck} from "../input/validation";
import axios from "axios";

let $SendFormContainers = document.querySelectorAll('.sform-container')

let loaderClass = 'button--load'

$SendFormContainers.forEach($SendFormContainer => {
    SendForm($SendFormContainer);
})

function SendForm($form) {
    let $formInputs = $form.querySelectorAll('.sform-input')
    let $formSenders = $form.querySelectorAll('.sform-sender')

    let sformTitle = $form.dataset.sformTitle;

    let reqData = {
        title: sformTitle,
        data: {}
    };


    $formSenders.forEach($formSender => {
        $formSender.addEventListener('click', e => {
            if ($formSender.classList.contains(loaderClass)) {
                return
            }

            if (checkInputData()) {
                $formSender.classList.add(loaderClass);
                send(reqData, $formSender)
            }
        })
    })

    function send(reqData, $formSender = null) {
        axios({
            method: 'POST',
            url: `${window.location.origin}/sendform/send`,
            data: reqData,
        }).then(() => {
            if ($formSender) {
                $formSender.classList.remove(loaderClass);
            }

            modals.close()
        }).catch((error) => {
            if ($formSender) {
                $formSender.classList.remove(loaderClass);
            }

            console.error(error);
        }).finally(() => {
            function sendYandexGoalAndRedirect() {
                return new Promise((resolve, reject) => {
                    let goalSent = false;

                    ym(ymCounterId, 'reachGoal', 'callback', null, function () {
                        goalSent = true;
                        resolve();
                    });

                    setTimeout(() => {
                        if (!goalSent) {
                            reject();
                        }
                    }, 3000);
                });
            }

            async function handleGoalAndRedirect() {
                try {
                    await sendYandexGoalAndRedirect();
                } catch (error) {
                    console.log('Failed to send goal within the timeout period or Yandex is blocked');
                } finally {
                    console.log('Goal reached');
                }
            }

            handleGoalAndRedirect();
        });

    }

    function fillData() {
        $formInputs.forEach($formInput => {
            let inputKey = $formInput.dataset.key
            let inputValue = $formInput.value

            reqData.data[inputKey] = inputValue;
        })

        return true;
    }

    function checkInputData() {
        if (validationCheck($form) == false) {
            return false;
        }

        if (fillData() == false) {
            return false;
        }

        return true
    }
}
