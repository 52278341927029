import {CreateSelect} from "../custom-select/custom-select";
import {getSelectValue} from "../../../utilities/getSelectValue/getSelectValue";
import {Timepicker} from "../timepicker/timepicker";
import {Input} from "../input/input";

export function OrderTimePlaceSelector({
                                           $wrapper,
                                           isAllowSelectTime,
                                           defaultTime,
                                           isAllowSelectPlace,
                                           defaultPlace,
                                           minTime = null,
                                           maxTime = null,
                                           deliveries,
                                           callback,
                                       }) {


    const classPrefix = 'order-time-place-selector';
    const dataPrefix = 'data-order-time-place-selector';
    const $template = document.querySelector(`[${dataPrefix}-template]`);
    const deliveryOtherHideClass = `${classPrefix}__delivery-other--hide`

    let time = defaultTime
    let delivery = defaultPlace
    let otherDelivery = ''

    const selectorCallback = () => {
        callback({time, delivery, otherDelivery})
    }

    let timePickerFunction = null
    let $time = null

    init()

    function init() {
        const $clone = document.importNode($template.content, true);
        $time = $clone.querySelector(`[${dataPrefix}-time]`)
        const $delivery = $clone.querySelector(`[${dataPrefix}-delivery]`)
        const $deliveryOther = $clone.querySelector(`[${dataPrefix}-delivery-other]`)

        new CreateSelect({
            $select: $delivery,
            selected: defaultPlace,
            placeholder: 'Выберите место',
            isHasDefault: isAllowSelectPlace,
            values: deliveries.map((delivery) => {
                return {...delivery, value: delivery.id}
            }).filter((delivery) => {
                return isAllowSelectPlace || delivery.id === defaultPlace
            }).concat(isAllowSelectPlace ? [{value: 'other', name: 'Другое'}] : [])
        })

        $delivery.addEventListener('change', () => {
            delivery = getSelectValue($delivery) ? getSelectValue($delivery) : null

            if (delivery === 'other') {
                $deliveryOther.classList.remove(deliveryOtherHideClass)
            } else {
                $deliveryOther.classList.add(deliveryOtherHideClass)
            }

            selectorCallback()
        })

        setMinMax()

        if (!isAllowSelectTime) {
            $time.setAttribute('disabled', 'disabled')
        }

        timePickerFunction = new Timepicker({
            $input: $time,
            initTime: defaultTime
        })

        $time.addEventListener('change', () => {
            const newTime = $time.value !== '' ? $time.value : null

            if (time === newTime) {
                return
            }

            time = newTime

            selectorCallback()
        })

        new Input({
            $wrapper: $deliveryOther,
            type: 'text',
            label: 'Другое место',
            name: 'place',
            isRequired: false,
            validationType: null,
            callback: ({value, isValid}) => {
                otherDelivery = value

                selectorCallback()
            }
        })

        selectorCallback()

        $wrapper.appendChild($clone);
    }

    function setMinMax() {
        if (minTime && $time.min !== minTime) {
            $time.min = minTime
        }

        if (maxTime && $time.max !== maxTime) {
            $time.max = maxTime
        }
    }

    function updateTimeLimits(options) {
        minTime = options.minTime ? options.minTime : minTime
        maxTime = options.maxTime ? options.maxTime : maxTime

        setMinMax()
    }

    this.updateTimeLimits = updateTimeLimits

}
