export function review({$wrapper, item}) {

    const classPrefix = 'review'
    const dataPrefix = 'data-review'

    const $source = $wrapper.querySelector(`[${dataPrefix}-source]`)
    const $rating = $wrapper.querySelector(`[${dataPrefix}-rating]`)
    const $avatar = $wrapper.querySelector(`[${dataPrefix}-avatar]`)
    const $avatarImg = $wrapper.querySelector(`[${dataPrefix}-avatar-img]`)
    const $avatarShortName = $wrapper.querySelector(`[${dataPrefix}-avatar-short-name]`)
    const $authorName = $wrapper.querySelector(`[${dataPrefix}-author-name]`)
    const $date = $wrapper.querySelector(`[${dataPrefix}-date]`)
    const $photos = $wrapper.querySelector(`[${dataPrefix}-photos]`)
    const $reviewText = $wrapper.querySelector(`[${dataPrefix}-review-text]`)
    const $responseText = $wrapper.querySelector(`[${dataPrefix}-response]`)

    $source.classList.add(`${classPrefix}__source--${item.source}`)
    $rating.classList.add(`${classPrefix}__rating--${item.rating}`)

    if (item.avatar_url) {
        $avatar.classList.add(`${classPrefix}__avatar--img`)
        $avatarImg.src = item.avatar_url
    } else {
        $avatar.classList.add(`${classPrefix}__avatar--short-name`)
    }

    $avatarShortName.innerText = item.short_name
    $authorName.innerText = item.author_name


    $date.innerText = new Date(item.date).toLocaleDateString('ru-RU')

    item.photos_urls.forEach((photo) => {
        const $img = document.createElement('img')
        $img.classList.add(`${classPrefix}__photo`)
        $img.src = photo
        $img.alt = item.author_name
        $photos.appendChild($img)
    })

    $reviewText.innerHTML = item.review_text
    $responseText.innerHTML = item.response_text
}