import {rentalCars} from "../rental-cars/rental-cars";

const $rentalCarsToogle = document.querySelector('.rental-cars-toggle');

if ($rentalCarsToogle) {
    rentalCarsToggle($rentalCarsToogle)
}

function rentalCarsToggle($wrapper) {
    const dataPrefix = 'data-rental-cars-toggle';
    const $button = $wrapper.querySelector(`[${dataPrefix}-show-catalog]`);
    const $catalogWrapper = $wrapper.querySelector(`[${dataPrefix}-catalog-wrapper]`);
    const $catalogTemplate = $wrapper.querySelector(`[${dataPrefix}-catalog-template]`);


    $button.addEventListener('click', () => {
        const $clone = document.importNode($catalogTemplate.content, true);

        $catalogWrapper.appendChild($clone);

        rentalCars($catalogWrapper.querySelector('.rental-cars'))

        const loadImage = (entries, observer) => {
            entries.forEach(entry => {
                // Если элемент является видимым
                if (entry.isIntersecting) {
                    const image = entry.target;
                    image.src = image.dataset.src;

                    // Отменить наблюдение за текущим изображением
                    observer.unobserve(image);
                }
            });
        };

        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.01
        };

        const observer = new IntersectionObserver(loadImage, options);

        $catalogWrapper.querySelectorAll('[data-loading="lazy"]').forEach(img => {
            observer.observe(img);
        });

        $button.remove();
    })

}