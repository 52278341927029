import {validationCheck} from "../input/validation";
import axios from "axios";
import {review} from "../review/review";

let $reviews = document.querySelector('.reviews')

if ($reviews) {
    reviews($reviews)
}

function reviews($wrapper) {
    const classPrefix = 'reviews'
    const dataPrefix = 'data-reviews'
    const loaderClass = 'button--load'

    let currentSkip = 100;

    const $form = $wrapper.querySelector(`[${dataPrefix}-form]`)
    let $send = $form.querySelector(`[${dataPrefix}-send]`)
    let $load = $wrapper.querySelector(`[${dataPrefix}-load]`)
    let $reviewsItems = $wrapper.querySelector(`[${dataPrefix}-reviews-items]`)
    let $reviewTemplate = $wrapper.querySelector(`[${dataPrefix}-review-template]`)

    const $name = $form.querySelector(`[data-key="name"]`)
    const $rating = $form.querySelector(`[data-key="rating"]`)
    const $text = $form.querySelector(`[data-key="text"]`)
    const $contacts = $form.querySelector(`[data-key="contacts"]`)

    let requiredData = ['name', 'rating', 'contacts'];

    function fillData() {

        let isFilled = true;

        requiredData.forEach((key) => {
            if ($form.querySelector(`[data-key="${key}"]`).value == '') {
                isFilled = false;
            }
        })

        return isFilled;
    }

    function checkInputData() {
        if (!validationCheck($form)) {
            return false;
        }

        if (!fillData()) {
            return false;
        }

        return true
    }

    $send.addEventListener('click', () => {


        if (!checkInputData()) {
            return
        }

        $send.classList.add(loaderClass);

        axios({
            method: 'POST',
            url: `/send-review`,
            data: {
                name: $name.value,
                rating: parseInt($rating.value),
                text: $text.value,
                contacts: $contacts.value
            },
        }).then(() => {
            if ($rating.value === '5') {
                window.modals.open('review-good-modal')
            } else {
                window.modals.open('review-bad-modal')
            }

            $name.value = '';
            $rating.value = '';
            $text.value = '';
            $contacts.value = '';
            $name.dispatchEvent(new Event('change'));
            $rating.dispatchEvent(new Event('change'));
            $text.dispatchEvent(new Event('change'));
            $contacts.dispatchEvent(new Event('change'));
        }).catch((error) => {
            alert(error.response.data.message);
        }).finally(() => {
            $send.classList.remove(loaderClass);
        })
    })

    $load.addEventListener('click', () => {
        $load.classList.add(loaderClass);

        axios({
            method: 'GET',
            url: `/reviews/get`,
            params: {
                skip: currentSkip,
                take: 30
            },
        }).then((response) => {
            response.data.reviews.forEach((item) => {
                const $reviewClone = document.importNode($reviewTemplate.content, true);
                const $review = $reviewClone.querySelector('.review');
                review({$wrapper: $review, item: item})
                $reviewsItems.appendChild($reviewClone);
            })

            currentSkip += 30;

            if (currentSkip >= response.data.count) {
                $load.remove();
            }
        }).catch((error) => {
            console.error(error)
            alert(error.response.data.message);
        }).finally(() => {
            $load.classList.remove(loaderClass);
        })
    })
}