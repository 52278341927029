import Swiper from "swiper";
import Navigation from 'swiper/modules/navigation.mjs';
import Thumbs from 'swiper/modules/thumbs.mjs';


export function rentalCarInfo($wrapper) {
    const classPrefix = 'rental-car-info';
    const dataPrefix = 'data-rental-car-info';

    const $gallery = $wrapper.querySelector(`[${dataPrefix}-gallery]`);
    const $galleryControl = $wrapper.querySelector(`[${dataPrefix}-gallery-control]`);

    const gallery = {
        main: null,
        control: null,
    }

    if ($gallery) {
        gallery.control = new Swiper($galleryControl, {
            spaceBetween: 10,
            slidesPerView: 4,
            freeMode: true,
            modules: [Navigation],
            watchSlidesProgress: true,
        });

        const checkGalleryControl = setInterval(() => {
            if (gallery.control) {
                clearInterval(checkGalleryControl);

                gallery.main = new Swiper($gallery, {
                    spaceBetween: 10,
                    navigation: {
                        nextEl: ".swiper-button-next",
                        prevEl: ".swiper-button-prev",
                    },
                    thumbs: {
                        swiper: gallery.control,
                    },
                    modules: [Navigation, Thumbs],
                });
            }
        })

    }

}


