export const cookie = {
    setObject: setCookieObject,
    getObject: getCookieObject,
    delete: deleteCookie,
}

// Функция для установки куки
function setCookieObject(name, value, days) {
    let expires = "";
    if (days) {
        const date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    const jsonString = JSON.stringify(value);
    document.cookie = name + "=" + encodeURIComponent(jsonString) + expires + "; path=/";
}

// Функция для получения куки
function getCookieObject(name) {
    const nameEQ = name + "=";
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) {
            const jsonString = decodeURIComponent(c.substring(nameEQ.length, c.length));
            return JSON.parse(jsonString);
        }
    }
    return null;
}

function deleteCookie(name) {
    document.cookie = name + "=; Max-Age=-99999999; path=/";
}