let $phoneOpen = document.querySelector('.phone-open');
let $phoneOpenContainer = document.querySelector('.phone-open-container');

let phoneOpenContainerActiveClass = 'phone-open-container--active'




if ($phoneOpen && $phoneOpenContainer){
    let $phoneCloseBtns = document.querySelectorAll('.phone-close-container');

    $phoneCloseBtns.forEach($phoneCloseBtn=>{
        if ($phoneCloseBtn){
            $phoneCloseBtn.addEventListener('click', e=>{
                $phoneOpenContainer.classList.remove(phoneOpenContainerActiveClass)
            })
        }

    })

    $phoneOpen.addEventListener('click', e=>{

        if ($phoneOpenContainer.classList.contains(phoneOpenContainerActiveClass))
        {
            $phoneOpenContainer.classList.remove(phoneOpenContainerActiveClass)
        }
        else {
            $phoneOpenContainer.classList.add(phoneOpenContainerActiveClass)
        }

    })



}
