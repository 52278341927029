import Swiper from 'swiper';

if (document.getElementsByClassName('swiper-stages')[0]) {
    let swiper = new Swiper(".swiper-stages", {
        speed: 400,
        spaceBetween: 10,
        autoHeight: true,
        grabCursor: true,
        slidesPerView: 'auto',
        centeredSlides: true,

        breakpoints: {
            // when window width is >= 320px
            600: {
                centeredSlides: false,
                slidesPerView: 3,
                spaceBetween: 10
            }
        },
    });
}
if (document.getElementsByClassName('swiper-news')[0]) {
    let swiperNews = new Swiper(".swiper-news", {
        spaceBetween: 10,
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
    });
}

let $contacstSwiper = document.querySelectorAll('.swiper-contacts')

$contacstSwiper.forEach($contacstSwiperItem => {
    initNewsGallery($contacstSwiperItem.dataset.id)
})

export function initNewsGallery(id) {
    let swiperContacts = new Swiper(".swiper-contacts-" + id, {
        spaceBetween: 2,
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
    });
}


// export function initGallery(id) {
//     let swiperGalleryControl = new Swiper(".swiper-gallery-control-" + id, {
//         spaceBetween: 10,
//         slidesPerView: 4,
//         freeMode: true,
//         watchSlidesProgress: true,
//     });
//
//     let swiperGallery = new Swiper(".swiper-gallery-" + id, {
//         spaceBetween: 10,
//         navigation: {
//             nextEl: ".swiper-button-next",
//             prevEl: ".swiper-button-prev",
//         },
//         thumbs: {
//             swiper: swiperGalleryControl,
//         },
//     });
// }

