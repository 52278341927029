import {DateTime} from "luxon";

export default function calcRentalDuration(startDate, endDate) {
    function parseDateToKaliningradTime(dateString, timeString) {
        const isoString = `${dateString}T${timeString}:00`;
        return DateTime.fromISO(isoString, {zone: 'Europe/Kaliningrad'});
    }

    const startDateTime = parseDateToKaliningradTime(startDate.date, startDate.time);
    const endDateTime = parseDateToKaliningradTime(endDate.date, endDate.time);

    const duration = endDateTime.diff(startDateTime, ['days', 'hours', 'minutes']);

    return {
        days: duration.days,
        minutes: duration.minutes + duration.hours * 60
    };
}